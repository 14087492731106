export const mainNavItems = [
  {
    icon: 'notifications',
    link: "/alerts",
    title: "Alerts",
    external: false,
  },
  {
    icon: "group",
    link: "/users",
    title: "Users",
    external: false,
  },
  {
    icon: "keys",
    link: "/api-keys",
    title: "API Keys",
    external: false,
  },
  {
    icon: "download",
    link: "/downloads",
    title: "Downloads",
    external: false,
  },
  {
    icon: 'person',
    link: "/agents",
    title: "Agents",
    external: false,
  },
  {
    icon: 'safety_check',
    link: "/audit",
    title: "Audit Trail",
    external: false,
  },
]

export const adminNavItems = [
  {
    icon: "corporate_fare",
    link: "/organizations",
    title: "Organizations",
    external: false,
  },
  // {
  //   icon: 'face',
  //   link: "/wipersnare/agents",
  //   title: "Wipersnare Agents",
  //   external: false,
  // },
  // {
  //   icon: 'query_stats',
  //   link: "/performance",
  //   title: "Performance Metrics",
  //   external: false,
  // },
  ...mainNavItems
]

export const msspNavItems = [
  adminNavItems[0],
  ...mainNavItems
]

export const securitysnaresNavItems = [
  {
    icon: 'bookmark',
    link: "/docs",
    title: "Documentation",
    external: false,
  },
  // {
  //   icon: 'summarize',
  //   link: "/log_alerts",
  //   title: "Log Alerts",
  //   external: false
  // }
]
