import Vue from 'vue';
import VueRouter from 'vue-router';
import DashboardLayout from "../layout/DashboardLayout.vue";
import AuthBasicLayout from "../layout/AuthBasicLayout.vue";
Vue.use(VueRouter);

// 
// THIS WILL CHECK IF USER IS LOGGED IN OR NOT
// 

export const LOGIN_CHECK_INTERVAL_MIN = 5;

export function checkValidity() {
  const token = localStorage.getItem('ransomsnare-token');
  const isTokenValid = (localStorage.getItem('ransomsnare-token-expiry') - (LOGIN_CHECK_INTERVAL_MIN*60)) > (new Date()/1000);
  const userInfo = localStorage.getItem('ransomsnare-userInfo');
  if (token && isTokenValid && userInfo)
    return true;
  return false;
}

export function checkClickwrapAcceptance() {
  const userInfo = JSON.parse(localStorage.getItem('ransomsnare-userInfo') ?? 'null')
  if (userInfo?.latest_clickwrap_accepted === 0) {
    return false
  }
  return true
}

export function redirectToMFA() {
  const userInfo = JSON.parse(localStorage.getItem('ransomsnare-userInfo') ?? 'null')
  const MFA_ENFORCED_FOR_ADMIN = process.env.VUE_APP_MFA_ENFORCED_FOR_ADMIN;
  const admin = userInfo?.is_securitysnares
  const mfa = userInfo?.mfa_enabled

  if (admin && !mfa && MFA_ENFORCED_FOR_ADMIN === 'true') {
    return true
  }
  return false
}

const routes = [
  {
    path: '/login',
    component: AuthBasicLayout,
    name: 'AuthBasicLayout',
    children: [
      {
        path: "/login",
        name: "Login",
        component: () => import( "../views/Authentication/Login.vue"),
        beforeEnter(to, from, next) {
          if (checkValidity()) {
            next(from.path)
          } else {
            next();
          }
        }
      },
      {
        path: "/session/login/:token/:uidb",
        name: "Session Login",
        component: () => import( "../views/Confirmation.vue"),
        beforeEnter(to, from, next) {
          if (checkValidity()) {
            next(from.path)
          } else {
            next();
          }
        }
      },
      {
        path: "/session/reset-password/:token/:uidb",
        name: "Session Password Reset",
        component: () => import("../views/Authentication/NewPassword.vue"),
        beforeEnter(to, from, next) {
          if (checkValidity()) {
            next(from.path)
          } else {
            next();
          }
        }
      },
      {
        path: "/signup",
        name: "Signup",
        component: () => import("../views/Authentication/Signup.vue"),
        beforeEnter(to, from, next) {
          if (checkValidity()) {
            next(from.path)
          } else {
            next();
          }
        }
      },
      {
        path: "/reset-password",
        name: "Reset Password",
        component: () => import("../views/Authentication/ResetPassword.vue"),
        beforeEnter(to, from, next) {
          if (checkValidity()) {
            next(from.path)
          } else {
            next();
          }
        }
      },
      {
        path: "/terms",
        name: "Terms of Service",
        meta: { requiresAuth: true },
        component: () => import("../views/Terms.vue"),
      }
    ]
  },
  {
    path: "*",
    component: DashboardLayout,
  },
  {
    path: "/",
    component: DashboardLayout,
    name: "Organizations",
    meta: { requiresAuth: true },
    beforeEnter(to, from, next) {
      const isValid = checkValidity();
      const hasAcceptedClickwrap = checkClickwrapAcceptance()
      if (isValid && hasAcceptedClickwrap) {
        next();
      } else if (isValid) {
        next('/terms');
      } else {
        next('/login');
      }
    },
    children: [
      {
        path: "/organizations",
        name: "Organizations",
        meta: { requiresAuth: true },
        component: () => import("../views/Organizations.vue"),
      },
      {
        path: "/users",
        name: "Users",
        meta: { requiresAuth: true },
        component: () => import("../views/Users.vue"),
      },
/*       {
        path: "/api-keys",
        name: "ApiKeys",
        meta: { requiresAuth: true },
        component: () => import("../views/ApiKeys.vue"),
      }, */
      {
        path: "/downloads",
        name: "Downloads",
        meta: { requiresAuth: true },
        component: () => import("../views/Downloads.vue"),
      },
      {
        path: "/performance",
        name: "Performance Metrics",
        meta: { requiresAuth: true },
        component: () => import("../views/Performance.vue"),
      },
      {
        path: "/agents",
        name: "Agents",
        meta: { requiresAuth: true },
        component: () => import("../views/Agents.vue"),
      },
      {
        path: "/docs",
        name: "Docs",
        meta: { requiresAuth: true },
        component: () => import("../views/Docs.vue"),
      },
/*       {
        path: "/securitysnares/dashboard",
        name: "SecuritySnares Dashboard",
        meta: { requiresAuth: true },
        component: () => import("../views/EmployeesDashboard.vue"),
      }, */
      {
        path: "/alerts",
        name: "Alerts",
        meta: { requiresAuth: true },
        component: () => import("../views/Alerts.vue"),
      },
      // {
      //   path: "/wipersnare/alerts",
      //   name: "Wipersnare Alerts",
      //   meta: { requiresAuth: true },
      //   component: () => import("../views/WipersnareAlerts.vue"),
      // },
      // {
      //   path: "/wipersnare/agents",
      //   name: "Wipersnare Agents",
      //   meta: { requiresAuth: true },
      //   component: () => import("../views/WipersnareAgents.vue"),
      // },
      {
        path: "/suspicious-processes",
        name: "Suspicious Processes",
        meta: { requiresAuth: true },
        component: () => import("../views/SuspiciousProcesses.vue"),
      },
      {
        path: "/mfa",
        name: "Multi-factor Authentication",
        meta: { requiresAuth: true },
        component: () => import("../views/MFASetup.vue"),
      },
      {
        path: "/audit",
        name: "Audit Trail Logs",
        meta: { requiresAuth: true },
        component: () => import("../views/AuditLogs.vue"),
      },
      // {
      //   path: "/log_alerts",
      //   name: "Log Alerts",
      //   meta: { requiresAuth: true },
      //   component: () => import("../views/LogAlerts.vue"),
      // }
    ],
  }
];

const BeforeEnterChildren = (to, from, next) => {
  // Check if the current route is already '/mfa' to prevent infinite loop
  if (to.path !== '/mfa') {
    const mfa = redirectToMFA();
    if (mfa) {
      next('/mfa');
    } else {
      next();
    }
  } else {
    next();
  }
};

const rootRoute = routes.find(route => route.path === '/');

rootRoute.children.forEach(child => {
  child.beforeEnter = BeforeEnterChildren
})

const router = new VueRouter({
  mode: 'history',
  routes
});

export default router;